import * as React from "react";
import Button from "@/components/Button/button";
import Section from "@/components/Section/Section";
import Col from "@/components/Col/Col";
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import Title from "@/components/Title/Title";
import makeMoney from "@/img/ico/makeMoney.svg";
import saveMoney from "@/img/ico/saveMoney.svg";
import attributionWindow from "@/img/ico/attributionWindow.svg";
import trueValue from "@/img/ico/trueValue.svg";
import theme__solution from "@/img/bg/theme__solution.svg";
import theme__solutionWorks from "@/img/bg/ilustrace_works.svg";
import CookieCompliance from "@/components/CookieCompliance/CookieCompliance";

const Solution = () => (
  <main>
    <CookieCompliance />
    <Header isSolution></Header>

    <Section colFlex vCentered className="hero">
      <Col size="50" className="hero__headline">
        <h1>Save & make money with ecomtrack</h1>
        <p>
          Ecomtrack’s Pixel will match your sales to the right campaigns and let
          you scale ads with certainty. Don’t waste money shooting in the dark
          because you’re relying on inaccurate data. Outsmart the limits of
          Facebook’s 7-day attribution window and exceed your revenue goals with
          ecomtrack.
        </p>
        <p></p>
        <div className="testimonial__logos__buttons">
          <Button
            {...{
              variant: "blue",
              scrollTarget: "#how-it-works",
              text: "Explore more",
            }}
          />
        </div>
      </Col>

      <Col size="50" className="">
        <img className="hero__theme" src={theme__solution} />
      </Col>
    </Section>

    <Section colFlex className="consequences" id="how-it-works">
      <Col size="50">
        <div className="consequences__title">
          <h6>Solution</h6>
          <h1>
            How does it <span className="violet">work</span>?
          </h1>
        </div>
        <div
          className="consequences__item"
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="500"
          data-sal-easing="ease-out"
        >
          <p className="consequences__item__no">01</p>
          <h3>Install ecomtrack Pixel</h3>
          <p>
            Our Pixel is installed on your ecommerce platform and it closely
            observes what every visitor is doing on your website – browsing
            pages, adding items to their cart, checking out – and builds an
            anonymous identity around each.
          </p>
        </div>

        <div
          className="consequences__item"
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="500"
          data-sal-easing="ease-out"
        >
          <p className="consequences__item__no">02</p>
          <h3>Place URL parameters in ads</h3>
          <p>
            By placing URL parameters in each ad, our Pixel will know if the
            visitor coming to your website has a unique Ad ID on his/her sleeve.{" "}
          </p>
        </div>

        <div
          className="consequences__item"
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="500"
          data-sal-easing="ease-out"
        >
          <p className="consequences__item__no">03</p>
          <h3>Check accurate data</h3>
          <p>
            When the visitor eventually makes a purchase our Pixel can assign
            credit back to the correct advertising platform and the specific ad
            that brought the customer to your website.
          </p>
        </div>
      </Col>
      <Col size="50">
        <img className="consequences__stick" src={theme__solutionWorks} />
      </Col>
    </Section>

    <Section className="solutionBenefits">
      <h3>
        Our Pixel will become your best friend because it gives you two crucial
        benefits
      </h3>

      <div className="solutionBenefits__items">
        <div className="solutionBenefits__items__item">
          <img src={saveMoney} />
          <div>
            <h4>Save Money</h4>
            <p>turn off ads that are not performing</p>
          </div>
        </div>
        <div className="solutionBenefits__items__item">
          <img src={makeMoney} />
          <div>
            <h4>Make Money</h4>
            <p>scale winning ads with confidence</p>
          </div>
        </div>
      </div>

      <div className="prizing__ready">
        <Col size="50">
          <h3>Ready to start tracking?</h3>
        </Col>
        <Col size="50">
          <Button
            {...{
              variant: "blue",
              link: `${global.app_url}/register`,
              text: "Start tracking",
            }}
          />
        </Col>
      </div>
    </Section>

    <Section className="solutionProfits">
      <div className="solutionProfits__item">
        <h2>Enjoy the profits</h2>
        <p>
          While others are wasting their budget on wrong campaigns, you are
          enjoying the profits from having accurate data and scaling only the
          ads that are bringing you the highest returns.
        </p>
        <Button
          {...{
            variant: "transparent",
            link: "/",
            text: "Explore more",
          }}
        />
      </div>

      <div className="solutionProfits__item">
        <img src={attributionWindow} />
        <h4>Choose attribution window</h4>
        <p>
          Our Pixel’s memory is not limited to just 7-days as Facebook’s
          (missing multiple purchases that happen after day 7) so you can choose
          from a 7-,14-, 28-day attribution window or „last click“ attribution
          model.
        </p>
      </div>

      <div className="solutionProfits__item">
        <img src={trueValue} />
        <h4>Know the truth</h4>
        <p>
          Finally, you will know the truth. Our Pixel’s goal is not to always
          track higher ROAS than Facebook’s ROAS (although mostly it’s the
          case!). Our goal is to tell you the truth about your attribution so
          you can make the most informed decisions based on it.
        </p>
      </div>
    </Section>

    <Section>
      <Title
        maintitle="You don’t know how much truth you are missing until you employ ecomtrack’s Pixel into your system."
        subtitle="Conclusion"
      ></Title>

      <div className="prizing__ready">
        <Col size="50">
          <h3>Ready to start tracking?</h3>
        </Col>
        <Col size="50">
          <Button
            {...{
              variant: "blue",
              link: `${global.app_url}/register`,
              text: "Start tracking",
            }}
          />
        </Col>
      </div>
    </Section>

    <Footer colFlex></Footer>
  </main>
);
export default Solution;
